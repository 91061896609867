import React, { Component } from 'react';

import breadcrumbs from './breadcrumbs';
import CartComponent from '../components/cart/CartArticle';

class Cart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location, site } = this.props;

    return (
      <CartComponent breadcrumbs={breadcrumbs('CART', location)} site={site} />
    );
  }
}

export default Cart;
