import Helmet from 'react-helmet';
import React from 'react';
import { navigate } from 'gatsby';

import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import Cart from '../_common/cart/Cart';

export default ({ breadcrumbs, site }) => {
  return (
    <article className="page article cart-page">
      <Helmet title={`Cart`} />
      <Breadcrumbs breadcrumbs={breadcrumbs} site={site} />

      <header className="article__header">
        <div className="article__featured">
          <div className="article__featured-text">
            <h1 className="article__title">CART</h1>
          </div>
        </div>
      </header>

      <div className="article__section">
        <Cart onContinueShopping={() => navigate('/')} site={site} />
      </div>
    </article>
  );
};
